/* Logo.css */
body {
	background: #111;
	overflow: hidden;
}

.logo-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
}

.logo-container img {
	max-width: 50%;
	height: auto;
	max-height: 50%;
}
